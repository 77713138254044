<template>
  <vs-card>
    <vs-form autocomplete="off">
      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h3 class="font-bold">Create Reporting Category</h3>
          </div>
          <div class="w-full m-5 custom-btn" align="right">
            <vs-button
              :disabled="!validateForm"
              @click="saveData"
              icon-pack="feather"
              class="mr-2"
              >Save</vs-button
            >
            <vs-button @click="cancelHandler" type="border"
              >Cancel</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
      <vs-divider />
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full sm:w-1/2 m-5">
            <vs-input
              :success="!errors.first('basic.name') && categoryData.name != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.name') ? true : false"
              v-validate="'required'"
              name="name"
              data-vv-as="Name"
              label="Reporting Category Name"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.name')"
              v-model="categoryData.name"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full sm:w-1/2 m-5">
            <vs-input
              :success="!errors.first('basic.slug') && categoryData.slug != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.slug') ? true : false"
              v-validate="{
                required: true,
                regex: /^[a-zA-Z0-9_\-\\.]*$/i
              }"
              name="slug"
              data-vv-as="slug"
              label="Reporting Category Slug"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.slug')"
              v-model="categoryData.slug"
              placeholder="Example-slug"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full sm:w-1/2 m-5">
            <label class="vs-input--label">Parent Reporting Category - Optional</label>
            <vs-select
              data-vv-as="Parent Reporting Category"
              name="Parent Reporting Category"
              autocomplete
              v-model="categoryData.parentCategory"
              class="w-full"
              @change="categoryData.parentCategory === 'None' ? '' : categoryData.isPopular = false"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in parentCategoryItem"
              />
            </vs-select>
            <span class="text-danger" style="font-size: 0.65rem !important">
              {{ errors.first("parentCategory") }}
            </span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <label class="vs-input--label">Reporting Category Description</label>
            <vue-ckeditor v-model="categoryData.productDescription" :config="config"/>
          </div>
        </vs-col>
      </vs-row>
    </vs-form>
  </vs-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import axios from "@/axios";
import VueCkeditor from 'vue-ckeditor2';

export default {
  name: "AddCategory",
  components: {
    vSelect,
    VueCkeditor,
  },
  props: {
    categoryDetail: {
      type: Object,
      default: () => {},
    },
    update: {
      type: String,
    },
  },
  data() {
    return {
      categoryData: this.categoryDetail,
      parentCategoryItem: [],
      clinics: [],
      config: {
        toolbar: [
          {
            name: 'clipboard',
            items: ['Undo', 'Redo']
          },
          { name: 'styles', items: ['Format'] },
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Strike',
            ]
          },
          {
            name: 'pagragraph',
            items: [
              'NumberedList',
              'BulletedList',
            ]
          },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        ],
        versionCheck: false,
        height: 300
      },
      imageType: '',
      desktopImageUrl: '',
      mobileImageUrl: '',
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any()
      );
    },
  },
  methods: {
    ...mapActions("clinic", ["fetchAllClinics"]),
    handleDrop(event, type) {
      event.preventDefault();
      this.imageType = type;
      this.uploadImage(event.dataTransfer.files[0]);
    },
    handleFileChange(event, type) {
      this.imageType = type;
      this.uploadImage(event.target.files[0]);
    },
    uploadDesktopImage() {
      this.$refs.fileInputDesktop.click();
    },
    uploadMobileImage() {
      this.$refs.fileInputMobile.click();
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + this.imageType,
        scale: 0.6,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (this.imageType === "desktop") {
            self.categoryData.desktopImage = res.data.Location;
            this.desktopImageUrl = res.data.Location;
          } else if (this.imageType === "mobile") {
            self.categoryData.mobileImage = res.data.Location;
            this.mobileImageUrl = res.data.Location;
          }
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    async saveData() {
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      let newData = { ...this.categoryData };
      if (newData.associatedClinicIds) {
        newData = {
          ...newData,
          associatedClinicIds: newData.associatedClinicIds.map(({ value }) => ({
            _id: value,
          })),
        };
      }
      //Set matchingCondition to all by default
      newData.matchingCondition = "all";
      this.$emit("saveDetail", newData);
    },
    cancelHandler() {
      this.$emit("cancelCreate");
    },
    async fetchParentCategoryList() {
      try {
        const res = await this.$store.dispatch('reportingCategory/getParentReportingCategoryList');
        let list = res.data.data;
        let defaultValue = { label: "None", value: "None" };
        this.parentCategoryItem = list.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        });
        this.parentCategoryItem.unshift(defaultValue);
        this.categoryData.parentCategory = this.categoryData.parentCategory
          ? this.categoryData.parentCategory
          : "None";
      } catch (error) {
        this.$vs.notify({
          title: "Category Add",
          text: `Error occured while fetching parent reporting category list!`,
          color: "danger",
        });
      }
    },
  },
  async created() {
    try {
      await this.fetchParentCategoryList();
      const res = await this.fetchAllClinics();
      this.clinics = res.data.data.map(
          ({ _id: value, clinicName: label }) => ({
            label,
            value,
          })
        );
    } catch (error) {
      this.$vs.notify({
        title: "Repoting Category Add",
        text: `Error occured while fetching`,
        color: "danger",
      });
    }
  },
};
</script>

<style>
.custom-btn .vs-button-filled{
    border: 2.5px solid rgba(var(--vs-primary), 1) !important;
  }
</style>
